<template>
  <div class="product-list">
    <!-- 标题与保存按钮 -->
    <el-row>
      <el-col :span="12"><Title title="用户问题反馈"></Title></el-col>
      <el-col :span="12">
        <div style="width:100%;height:60px;padding-right:15px;box-sizing:border-box" class="flex_rec">
          <!-- <el-button type="primary" @click="addObj">添加音效</el-button> -->
        </div>
      </el-col>
    </el-row>
    <!-- 产品列表 -->
    <el-card class="box-card">
      <el-row :gutter="20" type="flex" align="middle" justify="start">
        <el-col :span="4">
          <el-input placeholder="请输入内容" v-model="search_op.search_key" @change="getList" clearable>
            <template slot="prepend">关键词</template>
          </el-input>
        </el-col>
        <el-col :span="1.5">
          创建时间
        </el-col>
        <el-col :span="3">
          <el-date-picker
            @change="getList"
            v-model="search_op.create_time_range"
            type="daterange"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-col>
      </el-row>

      <el-row :gutter="20" type="flex" align="middle" justify="start" class="mt15">
        <el-col :span="4">
          <el-input placeholder="请输入内容" v-model="search_op.user_name" @change="getList" clearable>
            <template slot="prepend">用户名</template>
          </el-input>
        </el-col>
      </el-row>

      <el-table
        :data="list"
        @selection-change="handleSelectionChange"
        style="width: 100%">
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-row :gutter="20" class="mt10">
              <el-col :span="6">
                <p>
                  {{props.row.suggester}}
                </p>
              </el-col>
            </el-row>
            <!-- <el-row :gutter="20" class="mt10">
              <el-col :span="24">
                <p>
                  {{props.row.answer}}
                </p>
              </el-col>
            </el-row> -->
          </template>
        </el-table-column>

        <el-table-column
          prop="suggestion"

          label="建议内容">
        </el-table-column>

        <el-table-column
          prop="username"
          width="100"
          label="意见人">
        </el-table-column>

        <!-- <el-table-column
          prop="is_read"
          label="阅读"
          width="100"
          >
          <template slot-scope="scope">
            <template v-if="scope.row.is_read==1">
              <el-tag type="info" style="margin-right:5px">已阅读</el-tag>
            </template>
            <template v-if="scope.row.is_read==0">
              <el-tag type="danger" style="margin-right:5px">未阅读</el-tag>
            </template>
          </template>
        </el-table-column> -->
        <!-- <el-table-column
          prop="is_answer"
          label="回答"
          width="100"
          >
          <template slot-scope="scope">
            <template v-if="scope.row.is_answer==1">
              <el-tag type="info" style="margin-right:5px">已回答</el-tag>
            </template>
            <template v-if="scope.row.is_answer==0">
              <el-tag type="danger" style="margin-right:5px">未回答</el-tag>
            </template>
          </template>
        </el-table-column> -->


        <el-table-column
          prop="time"
          width="80"
          label="创建时间">
        </el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <!-- <el-button
              size="mini"
              icon="el-icon-edit"
              @click="handleEdit(scope.$index, scope.row)">编辑</el-button> -->
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page_num"
      :page-sizes="[5,10,15,20]"
      :page-size="page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    <div style="margin-top: 20px">
      <el-button v-if="selects.length>0" @click="handleDelete(selects)">删除选中</el-button>
    </div>
  </div>
</template>

<script>
import Title from "./components/Title"
import apis from "@/api/index"
import util from "@/lib/util.js"
export default {
    components:{
      Title,
    },
    name:'FindSound',
    data(){
      return {
        list:[],
        selects:[],
        page_num:1,
        page_size:5,
        total:0,
        search_op:{

        },
      }
    },
    methods:{
			confirm(ob,callback){
				this.$confirm(ob.msg, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: !ob.type?'warning':ob.type
				}).then(() => {
					callback()
				}).catch((e) => {
					console.error(e)
					this.$message({
						type: 'info',
						message: '已取消'
					});          
				});
			},
      getList(toS=true){
        if(toS){
          this.page_num = 1;
        }
        let op = {
          page_num:this.page_num,
          page_size:this.page_size
        }
        Object.assign(op,this.search_op)
        apis.suggestions.read(op).then(res=>{
          console.log('res',res)
          this.list = (res.data.data.list || []).map((v)=>{
            return v;
          })
          this.total = res.data.data.count
        }).catch(err=>{
          console.log('err',err)
        })
      },
      handleEdit(index, row) {
        // this.$router.push({name:'answerFindSound',params:{obj:row},query:{id:row.id}})
        // console.log(index, row);
      },
      handleSelectionChange(val){
        this.selects = val.map(item=>item.id)
      },
      handleDelete(row) {
        const isIds = util.isArray(row)
        
				let callback = ()=>{
          let op = {'ids':isIds?row:[row.id]}
          apis.suggestions.delete(op).then(res=>{
            console.log('res',res)
            if(res.data.code == 1){
              this.$message({message:"删除成功", type:"success"})
              this.getList(false)
            }else{
              this.$message({message:res.data.msg, type:"error"})
            }
          }).catch(err=>{
            this.$message({message:err, type:"error"})
          })
				}
        console.log(row)
        const msg = isIds?row.length+"项":row.suggestion
				this.confirm({msg:`确认删除 [${msg}]`},callback)
      },
      handleSizeChange(val) {
        this.page_size = val
        this.getList(false)
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        this.page_num = val
        this.getList(false)
        console.log(`当前页: ${val}`);
      },
      // addObj(){
      //   this.$router.push({name:'addSound'})
      // }
    },
    activated(){
      this.getList(false)
    },
    mounted(){
      this.getList()

    }
}
</script>

<style lang="scss" scoped>
.product-list{
  padding:15px;
}

</style>